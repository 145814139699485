import checks from "assets/images/backgrounds/checks.png";
import messageIcon from "assets/images/pages/journey/message.webp";
import partnershipIcon from "assets/images/pages/journey/partnership.webp";
import searchIcon from "assets/images/pages/journey/search.webp";
import teachingIcon from "assets/images/pages/journey/teaching.webp";
import CopyReferenceCode from "components/CopyReferenceCode";
import DaysLeft from "components/DaysLeft";
import Profile from "components/Profile";
import TopMenu from "components/TopMenu";
import { AuthContext } from "context/Auth";
import useDocumentTitle from "hooks/useDocumentTitle";
import useLayoutConfig from "hooks/useLayoutConfig";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

const overlayStyle = {
  backgroundPosition: "center center",
  borderRadius: "50px 50px 35px 35px",
  backgroundColor: "#fff",
  backgroundImage: `url(${checks})`,
};

const Lock = (
  <div className="absolute -right-1 translate-x-full">
    <div className="w-[10px] h-[8px] bg-[#9d9d9d] rounded-[1px] relative before:content-['']  before:w-[6px] before:h-[4px] before:border-[2px] before:border-solid before:border-[#9d9d9d] before:border-b-0 before:rounded-t-[10px] before:rounded-[10px_10px_0_0] before:absolute before:top-[-2px] before:left-1/2 before:-translate-x-1/2 before:translate-y-[-50%] before:bg-transparent"></div>
  </div>
);

const Journey = () => {
  const { profile, setProfile } = useContext(AuthContext);
  const [currentStep, setCurrentStep] = useState(1);
  const [showMainPage, setShowMainPage] = useState(true);

  useLayoutConfig("purple", false);
  useDocumentTitle("Muskurahat Foundation | Explore");

  const navigate = useNavigate();

  const steps = {
    1: (
      <>
        <div>
          <div className="py-10">
            <div style={{ padding: "177.78% 0 0 0", position: "relative" }}>
              <iframe
                className="rounded-2xl"
                src="https://player.vimeo.com/video/1072110053?h=f9f72ede7e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
                title="Journey - 01"
              ></iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
          </div>
          <h2 className="text-secondary text-base font-bold mt-5 flex justify-start gap-2">
            Step 1 : Here&apos;s How to Find Your Donors{" "}
            <img src={searchIcon} className="w-9 h-9" />
          </h2>

          <ul className="text-xs list-disc">
            <li className="mt-4 leading-[17px] text-[13px]">
              Grab a notebook or open a notes app.
            </li>
            <li className="mt-4 leading-[17px] text-[13px]">
              List at least 20-30 names of potential donors. (Yes, challenge
              yourself!)
            </li>
            <li className="mt-4 leading-[17px] text-[13px]">
              Categorize them into Close Circle (easy to ask), Extended Circle
              (may need more convincing), and High-Potential Donors (big
              contributors).
            </li>
            <li className="mt-4 leading-[17px] text-[13px]">
              Start thinking about how to approach them—what would resonate with
              them?
            </li>
          </ul>

          <div className="bg-white shadow-lg p-4 my-10">
            <h3 className="text-[16px] uppercase text-primary font-bold">
              Remember 💡
            </h3>
            <p className="text-secondary text-[13px]">
              The more specific and intentional you are in finding your donors,
              the easier it will be to reach your goal!
            </p>
          </div>

          <p className="text-[13px]">Now, let’s get started! 🚀</p>

          <div className="flex gap-2">
            <button
              onClick={() => {
                if (profile.journeyStep === 1)
                  setProfile({ ...profile, journeyStep: 2 });
                setCurrentStep(2);
              }}
              className="text-white bg-primary font-bold px-3 py-2 mt-5 rounded-lg"
            >
              NEXT
            </button>
          </div>
        </div>
      </>
    ),
    2: (
      <>
        <div>
          <div className="py-10">
            <div style={{ padding: "177.78% 0 0 0", position: "relative" }}>
              <iframe
                className="rounded-2xl"
                src="https://player.vimeo.com/video/1072110202?h=18afabcac0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
                title="Journey - 02"
              ></iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
          </div>

          <h2 className="text-secondary text-base font-bold mt-5 flex justify-start gap-2">
            Step 2: How to Pitch Like a Pro Turn Your Donor List into Real
            Impact
            <img src={searchIcon} className="w-9 h-9" />
          </h2>

          <p className="text-xs leading-[15px] mt-5 mb-10">
            This is your first rodeo in expressing yourself—learning how to
            communicate with impact, make a compelling ask, and inspire action.
          </p>

          <div className="bg-white shadow-lg p-7 my-10">
            <h3 className="font-bold">Now, Your Today’s Task 💡</h3>
            <ul className="list-disc">
              <li className="mt-3">
                Pick 5 names from your list and craft a pitch for each based on
                what would resonate with them.
              </li>
              <li className="mt-3">
                Test it out—send your first message today.
              </li>
              <li className="mt-3">
                Track your results—who responded? What worked? What didn’t?
                Adjust as needed.
              </li>
            </ul>
          </div>

          <h3 className="text-[16px] uppercase text-primary font-bold">
            BONUS 🔥
          </h3>
          <p className="text-secondary text-[13px]">
            If you get a donation using these techniques, let Samay know for a
            one on one jam session.
          </p>

          <div className="flex gap-2">
            <button
              onClick={() => {
                setCurrentStep(1);
              }}
              className="text-white bg-secondary font-bold px-3 py-2 mt-5 rounded-lg"
            >
              PREVIOUS
            </button>
            <button
              onClick={() => {
                if (profile.journeyStep === 2)
                  setProfile({ ...profile, journeyStep: 3 });
                setCurrentStep(3);
              }}
              className="text-white bg-primary font-bold px-3 py-2 mt-5 rounded-lg"
            >
              NEXT
            </button>
          </div>
        </div>
      </>
    ),
    3: (
      <form autoComplete="off">
        <div>
          <div className="py-10">
            <div style={{ padding: "177.78% 0 0 0", position: "relative" }}>
              <iframe
                className="rounded-2xl"
                src="https://player.vimeo.com/video/1072109900?h=df4c841e59&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
                title="Journey - 03"
              ></iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
          </div>

          <h2 className="text-secondary text-base font-bold mt-5 flex justify-start gap-2">
            Step 3: How to Build Trust With Your Donors
            <img src={searchIcon} className="w-9 h-9" />
          </h2>

          <p className="text-xs leading-[15px]">
            Trust isn’t built overnight—it’s earned through honesty,
            transparency, and real impact.
          </p>
          <div className="bg-white shadow-lg p-7 my-10">
            <h3 className="text-[16px] uppercase text-primary font-bold">
              Task 🎯
            </h3>
            <p className="text-secondary text-[13px]">
              Before reaching out to donors, familiarize yourself with these
              resources. Scroll through our Instagram, check out the reports,
              and be ready to answer any questions. The more confident you are,
              the more trust you’ll build!
            </p>
          </div>

          <div className="flex gap-2">
            <button
              onClick={() => {
                setCurrentStep(2);
              }}
              className="text-white bg-secondary font-bold px-3 py-2 mt-5 rounded-lg"
            >
              PREVIOUS
            </button>
            <button
              onClick={() => {
                if (profile.journeyStep === 3)
                  setProfile({ ...profile, journeyStep: 4 });
                setCurrentStep(4);
              }}
              className="text-white bg-primary font-bold px-3 py-2 mt-5 rounded-lg"
            >
              NEXT
            </button>
          </div>
        </div>
      </form>
    ),
    4: (
      <>
        <div>
          <div className="py-10">
            <div style={{ padding: "177.78% 0 0 0", position: "relative" }}>
              <iframe
                className="rounded-2xl"
                src="https://player.vimeo.com/video/1072109850?h=c101007ca1&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
                title="Journey - 04"
              ></iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
          </div>

          <h2 className="text-secondary text-base font-bold mt-5 flex justify-start gap-2 uppercase">
            STEP 4: Understanding Why Donors Procrastinate & How to Overcome It
            <img src={partnershipIcon} className="w-9 h-9" />
          </h2>

          <div className="bg-white shadow-lg p-7 my-10">
            <p>
              Procrastination is a real thing, even when it comes to making a
              difference. If you are going through this challenge, then this
              video is going to be a treasure trove for you.
            </p>
          </div>

          <h3 className="text-[16px] uppercase text-primary font-bold mt-10">
            If your donors are procrastinating, here’s what you need to do
          </h3>
          <p className="text-secondary text-[13px]">
            Think about the potential donors from your list. Who are the ones
            who might need an extra nudge? Try applying these techniques in your
            next conversation and see how they respond! 🚀
          </p>

          <div className="flex gap-2">
            <button
              onClick={() => {
                setCurrentStep(2);
              }}
              className="text-white bg-secondary font-bold px-3 py-2 mt-5 rounded-lg"
            >
              PREVIOUS
            </button>
            <button
              onClick={() => {
                if (profile.journeyStep === 4)
                  setProfile({ ...profile, journeyStep: 5 });
                navigate("/user/dashboard");
              }}
              className="text-white bg-primary font-bold px-3 py-2 mt-5 rounded-lg"
            >
              BACK TO DASHBOARD
            </button>
          </div>
        </div>
      </>
    ),
  };

  return (
    <div>
      <div className="flex m-4">
        <div className="w-1/2">
          <DaysLeft />
        </div>
        <div className="w-1/2">
          <div className="flex justify-end gap-4">
            <CopyReferenceCode />
            <Profile />
          </div>
        </div>
      </div>

      <TopMenu />
      <div style={overlayStyle}>
        {showMainPage ? (
          <div className="py-20 px-12">
            <div className="bg-[url('assets/images/pages/journey/pinboard.webp')] bg-cover bg-no-repeat pl-5 pt-8 pb-1 mb-10 mt-5">
              <h1 className="font-semibold uppercase text-secondary mb-5">
                Crowdfunding Is A Skill
                <br /> That Will Teach You How To
              </h1>
              <p className="text-[13px] mb-3 flex justify-start gap-2">
                1. Find Your Audience
                <img src={searchIcon} className="w-9 h-9 mt-[-5px]" />
              </p>
              <p className="text-[13px] mb-3 flex justify-start gap-2">
                2. Create A Pitch For That Audience{" "}
                <img src={teachingIcon} className="w-9 h-9 mt-[-5px]" />
              </p>
              <p className="text-[13px] mb-3 flex justify-start gap-2">
                3. Build Trust
                <img src={partnershipIcon} className="w-9 h-9 mt-[-5px]" />
              </p>
              <p className="text-[13px] mb-3 flex justify-start gap-2">
                4. Identify Their Behaviours
                <img src={messageIcon} className="w-9 h-9 mt-[-5px]" />
              </p>
            </div>
            <h2 className="text-black font-bold text-base mb-5">
              Introducing the <br />
              <span className="text-primary">
                How to Raise Funds&apos; Series
              </span>
            </h2>

            <p className="text-[13px] mb-10">
              packed with actionable tips to help you fundraise effectively
              while mastering the art of connection and expression. 🚀
            </p>

            <button
              className="bg-primary font-bold text-white px-3 py-2 rounded-lg mt-5"
              onClick={() => setShowMainPage(false)}
            >
              Start Upskilling Now
            </button>
          </div>
        ) : (
          <div className="py-8 px-12">
            <div className="flex justify-center items-center gap-5">
              <div className="font-bold text-secondary">STEP</div>
              <div
                onClick={() => {
                  setCurrentStep(1);
                }}
                className={`${
                  (profile.journeyStep ?? 1) >= 1
                    ? "bg-secondary"
                    : "bg-[#9D9D9D]"
                } w-7 h-7 rounded-full flex justify-center items-center text-white relative after:absolute after:top-1/2 after:right-0 after:content-[''] after:border-t-[0.3px] after:border-dashed after:w-5 after:translate-x-full after:border-[#9d9d9d]`}
              >
                {(profile.journeyStep ?? 1) === 1 ? Lock : null}1
              </div>
              <div
                onClick={() => {
                  if (profile.journeyStep >= 2) setCurrentStep(2);
                }}
                className={`${
                  profile.journeyStep >= 2 ? "bg-tertiary" : "bg-[#9D9D9D]"
                } w-7 h-7 rounded-full flex justify-center items-center text-white relative after:absolute after:top-1/2 after:right-0 after:content-[''] after:border-t-[0.3px] after:border-dashed after:w-6 after:translate-x-full after:border-[#9d9d9d]`}
              >
                {profile.journeyStep === 2 ? Lock : null}2
              </div>
              <div
                onClick={() => {
                  if (profile.journeyStep >= 3) setCurrentStep(3);
                }}
                className={`${
                  profile.journeyStep >= 3 ? "bg-primary" : "bg-[#9D9D9D]"
                } w-7 h-7 rounded-full flex justify-center items-center text-white relative after:absolute after:top-1/2 after:right-0 after:content-[''] after:border-t-[0.3px] after:border-dashed after:w-6 after:translate-x-full after:border-[#9d9d9d]`}
              >
                {profile.journeyStep === 3 ? Lock : null}3
              </div>
              <div
                onClick={() => {
                  if (profile.journeyStep >= 4) setCurrentStep(4);
                }}
                className={`${
                  profile.journeyStep >= 4 ? "bg-tertiary" : "bg-[#9D9D9D]"
                } w-7 h-7 rounded-full flex justify-center items-center text-white`}
              >
                {profile.journeyStep === 4 ? Lock : null}4
              </div>
            </div>
            {steps[currentStep]}
          </div>
        )}
      </div>
    </div>
  );
};

export default Journey;
