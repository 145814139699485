const VideoPopup = ({ showVideoPopup, setShowVideoPopup }) => {
  return (
    showVideoPopup && (
      <div
        onClick={() => setShowVideoPopup(null)}
        className="absolute w-full h-full left-0 top-0 bg-black bg-opacity-50 z-[9999] flex justify-center items-center"
      >
        <div
          onClick={() => setShowVideoPopup(null)}
          className="w-full h-full max-w-[800px] bg-transparent relative flex justify-center items-center"
        >
          <div className="relative w-full" style={{ padding: "177.78% 0 0 0" }}>
            <div className="absolute z-[9999999] top-0 left-0 w-full flex justify-end">
              <button
                className="bg-black p-2 m-4 rounded-full text-white font-bold z-[99999]"
                onClick={() => setShowVideoPopup(null)}
              >
                X
              </button>
            </div>
            <iframe
              src={`${
                showVideoPopup === "intro-to-cause"
                  ? "https://player.vimeo.com/video/1067615014?h=c76ed32e96&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  : "https://player.vimeo.com/video/1067659763?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              }`}
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
              style={{
                position: "absolute",
                top: "50%",
                left: "0",
                width: "100%",
                height: "100%",
                transform: "translate(0%, -50%)",
              }}
              title="Intro To The Cause"
            ></iframe>
          </div>
          <script src="https://player.vimeo.com/api/player.js"></script>
        </div>
      </div>
    )
  );
};

export default VideoPopup;
