import Fish from "assets/images/pages/onboarding/fish.webp";
import HumanGroup from "assets/images/pages/onboarding/group-humans.webp";
import Human2 from "assets/images/pages/onboarding/human-2.webp";
import Human from "assets/images/pages/onboarding/human.webp";
import HumanImage from "assets/images/pages/onboarding/image-human.webp";
import BoySun from "assets/images/pages/onboarding/sun-boy.webp";
import GroupMan from "assets/images/pages/onboarding/groupman.webp";
import Man from "assets/images/pages/onboarding/man.webp";
import Lets from "assets/images/pages/onboarding/lets.webp";
import { AuthContext } from "context/Auth";
import useLayoutConfig from "hooks/useLayoutConfig";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const InternshipOnboarding = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isStepComplete, setIsStepComplete] = useState(false);
  const [showError, setShowError] = useState(false);
  useLayoutConfig("red", false);
  const { profile, setProfile } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    city: "",
    educationalQualifications: "",
    reason: "",
  });

  let navigate = useNavigate();

  useEffect(() => {
    switch (currentStep) {
      case 11:
      case 12:
      case 13:
      case 14:
        setIsStepComplete(false);
        break;
      case 15:
        if (formData.city.length > 0) setIsStepComplete(true);
        else setIsStepComplete(false);
        break;
      case 16:
        if (formData.educationalQualifications.length > 0)
          setIsStepComplete(true);
        else setIsStepComplete(false);
        break;
      case 17:
        if (formData.reason.length > 50) setIsStepComplete(true);
        else setIsStepComplete(false);
        break;
      default:
        setIsStepComplete(true);
    }
  }, [currentStep, formData]);

  //   useEffect(() => {
  //     sendGTMEvent({ event: "intern_form_filled", step: currentStep });
  //   }, [currentStep]);

  const handleNext = () => {
    setShowError(false);
    if (currentStep === steps.length - 1) return;
    setCurrentStep((prev) => prev + 1);
  };

  const handlePrev = () => {
    if (currentStep === 0) return;
    setCurrentStep((prev) => prev - 1);
  };

  // use effect to check if profile has been welcomed before or not. if not, navigate to welcome component
  useEffect(() => {
    if (profile && profile.welcomed) {
      // react router navigate
      navigate("/user/dashboard");
    }
  }, []);

  const handleSubmit = async () => {
    setIsLoading(true);
    let setFormData = new FormData();
    setProfile({
      ...profile,
      onboarded: true,
      onboardedOn: new Date(),
      city: formData.city,
      educationalQualifications: formData.educationalQualifications,
      reason: formData.reason,
    });

    setFormData.append(
      "createdOn",
      `${new Date().toDateString()} ${new Date().toLocaleTimeString()}`,
    );
    setFormData.append("city", formData.city);
    setFormData.append("reason", formData.reason);

    await fetch(
      "https://script.google.com/macros/s/AKfycbz-zR7WFnC_cFv1e5Ls_QQOTVhzIz_xWYeuRK1EaSA0EgAXbC4Emi4hAZ7AIsXzonsI/exec",
      {
        method: "POST",
        body: setFormData,
      },
    );
    // sendGTMEvent({ event: "intern_form_filled", step: "submitted" });

    setIsLoading(false);
  };

  const steps = [
    // 1
    {
      body: (
        <div className="text-center px-10">
          <h2 className="text-tertiary font-bold text-[20px]">
            Average attention span of a Goldfish is 9 seconds.
          </h2>
          <h2 className="font-bold text-[20px]">
            Average attention span of a human is 8.25 Seconds.
          </h2>
          <h3 className="text-[16px] mt-5">
            So if you&apos;re not ready to{" "}
            <span className="font-bold">focus</span> then this internship{" "}
            <span className="font-bold">IS NOT FOR YOU.</span>
          </h3>
          <button
            onClick={handleNext}
            className="bg-primary text-white rounded-[13px] px-5 py-2 mt-5 font-bold text-[18px] relative z-10"
          >
            I Can Focus
          </button>
          <div className="absolute bottom-10 left-0 px-10 w-full justify-between flex">
            <img
              alt="Fish"
              src={Fish}
              height={80}
              width={120}
              className="self-center"
            />
            <img alt="Human" src={Human} height={190} width={220} />
          </div>
        </div>
      ),
    },
    // 2
    {
      body: (
        <div className="text-center px-10">
          <h3 className="text-[20px] mt-">
            This is an opportunity to support quality education, social
            emotional learning & mental well-being of over
            <span className="font-bold"> 1500 underprivileged children </span>
            Plus, master the
            <span className="font-bold text-[20px]"> Art of Expression- </span>
            to learn a 21st-Century must-have skill & become the voice of our
            cause
          </h3>

          <button
            onClick={handleNext}
            className="bg-primary text-white rounded-[13px] px-5 py-2 mt-5 font-bold text-[18px] z-10 relative"
          >
            I Am In
          </button>
          <div className="absolute bottom-0 left-0 px-10 w-full justify-center flex">
            <img alt="HumanImage" src={HumanImage} height={250} width={350} />
          </div>
        </div>
      ),
    },
    // 3
    {
      body: (
        <div className="text-center px-10">
          <h3 className="text-[20px] mt-5">
            <span className="font-bold">Access to education</span> has been
            largely achieved in India. However, quality in education is a
            challenge to overcome. Resulting in children in India being{" "}
            <span className="font-bold">schooled but not educated.</span>
          </h3>

          <button
            onClick={handleNext}
            className="bg-primary text-white rounded-[13px] px-5 py-2 mt-5 font-bold text-[18px] z-10 relative"
          >
            What can be done?
          </button>
          <div className="absolute bottom-0 left-0 px-10 w-full justify-center flex">
            <img alt="BoySun" src={BoySun} height={250} width={350} />
          </div>
        </div>
      ),
    },
    // 4
    {
      body: (
        <div className="text-center px-10">
          <h3 className="text-[20px] mt-5">
            We need to{" "}
            <span className="font-bold">future-proof the next generation,</span>{" "}
            today by working on three key aspects of child development
          </h3>
          <ul className="font-bold text-[20px] list-none mt-5">
            <li>● &nbsp;Social Emotional Learning</li>
            <li>● &nbsp;Academic Support </li>
            <li>● &nbsp;Mental Well-Being</li>
          </ul>

          <button
            onClick={handleNext}
            className="bg-primary text-white rounded-[13px] px-5 py-2 mt-5 font-bold text-[18px] z-10 relative"
          >
            Boy, That’s Deep!
          </button>
          <div className="absolute bottom-0 left-0 px-10 w-full justify-center flex">
            <img alt="Human2" src={Human2} height={250} width={350} />
          </div>
        </div>
      ),
    },
    // 5
    {
      body: (
        <div className="text-center px-10">
          <h3 className="text-[20px] mt-">
            Do you know 50% of applicants drop out by the time they reach here?
            Trust us to take you on a 30- day journey where we can guarantee an
            incredible internship experience.
          </h3>
          <h2 className="font-bold text-[20px] mt-5">
            The future will remember you!
          </h2>

          <button
            onClick={handleNext}
            className="bg-primary text-white rounded-[13px] px-5 py-2 mt-5 font-bold text-[18px] z-10 relative"
          >
            Tell Me MORE
          </button>
          <div className="absolute bottom-0 left-0 px-10 w-full justify-center flex">
            <img alt="GroupMan" src={GroupMan} height={250} width={350} />
          </div>
        </div>
      ),
    },
    // 6
    {
      body: (
        <div className="text-center px-10">
          <h3 className="text-[20px] mb-5">
            This is a virtual{" "}
            <span className="font-bold">crowdfunding internship.</span> You will
            advocate for the cause within your network and gather funds to
            support their education. The funds will be used to meet their
            educational requirements, like trained tutors, resources,
            sustenance, and reach more children.
          </h3>

          <button
            onClick={handleNext}
            className="bg-primary text-white rounded-[13px] px-5 py-2 mt-5 font-bold text-[18px] z-10 relative"
          >
            How Will I Pull That Off?
          </button>
          <div className="absolute bottom-0 left-0 px-10 w-full justify-center flex">
            <img alt="Man" src={Man} height={250} width={350} />
          </div>
        </div>
      ),
    },
    // 7
    {
      body: (
        <div className="text-center text-xl px-10">
          <h3>
            We will equip you with the right skills. This Internship Will Be
            Your Masterclass In Art Of Expression:
          </h3>
          <ul className="mt-5 text-left">
            <li className="flex items-start gap-2 mb-4">
              <span className="text-purple-600">🎨</span>
              <p>
                <strong>How to Connect Like a Pro:</strong> Learning how to
                become approachable & build meaningful relationships
              </p>
            </li>
            <li className="flex items-start gap-2">
              <span className="text-purple-600">📢</span>
              <p>
                <strong>A Crash Course in Personal Branding</strong>
              </p>
            </li>
          </ul>

          <button
            onClick={handleNext}
            className="bg-primary text-white rounded-[13px] px-5 py-2 mt-5 font-bold text-[18px] z-10 relative"
          >
            Continue
          </button>
          <div className="absolute bottom-0 left-0 px-10 w-full justify-center flex">
            <img alt="Human2" src={Human2} height={250} width={350} />
          </div>
        </div>
      ),
    },
    // 8
    {
      body: (
        <div className="text-center px-10 text-xl">
          <ul className="mt-5 text-left">
            <li className="flex items-start gap-2 mb-4">
              <span className="text-purple-600">📊</span>
              <p>
                <strong>Marketing 101:</strong> Learn fundamentals Of Marketing
              </p>
            </li>
            <li className="flex items-start gap-2 mb-4">
              <span className="text-purple-600">📖</span>
              <p>
                <strong>A Reality Check:</strong> A deeper understanding of
                societal challenges
              </p>
            </li>
            <li className="flex items-start gap-2">
              <span className="text-purple-600">💡</span>
              <p>
                <strong>The Power of Empathy:</strong> A perspective that will
                make you a better leader, teammate, & human
              </p>
            </li>
          </ul>

          <button
            onClick={handleNext}
            className="bg-primary text-white rounded-[13px] px-5 py-2 mt-5 font-bold text-[18px] z-10 relative"
          >
            All this in a 30 Day Internship?
          </button>
          <div className="absolute bottom-0 left-0 px-10 w-full justify-center flex">
            <img alt="Human2" src={Human2} height={250} width={350} />
          </div>
        </div>
      ),
    },
    // 9
    {
      body: (
        <div className="text-center text-xl px-10">
          <h3>
            Haha, it might sound to good to be true, but you can count on us for
            the same.
          </h3>
          <h3>
            <span className="font-bold">
              You will also take home a stipend, certificates and boatload of
              experience at the end of your internship.
            </span>
          </h3>

          <button
            onClick={handleNext}
            className="bg-primary text-white rounded-[13px] px-5 py-2 mt-5 font-bold text-[18px] z-10 relative"
          >
            I Am Mindblown
          </button>
          <div className="absolute bottom-0 left-0 px-10 w-full justify-center flex">
            <img alt="Human2" src={Human2} height={250} width={350} />
          </div>
        </div>
      ),
    },
    // 10
    {
      body: (
        <div className="text-center px-10 text-xl">
          <h3>
            However, before we proceed there are certain things we need from
            you.
          </h3>

          <ul className="list-none text-left mt-5">
            <li>
              ● &nbsp;<strong>This is serious stuff.</strong> You’re not just an
              intern. We expect you to stay committed throughout.
            </li>
            <li className="mt-3">
              ● &nbsp; <strong>Your communication matters.</strong> You’ll pitch
              our mission and vision to raise funds that will directly impact
              these kids.{" "}
            </li>
          </ul>

          <button
            onClick={handleNext}
            className="bg-primary text-white rounded-[13px] px-5 py-2 mt-5 font-bold text-[18px] z-10 relative"
          >
            Continue
          </button>
          <div className="absolute bottom-0 left-0 px-10 w-full justify-center flex">
            <img alt="Human2" src={Human2} height={250} width={350} />
          </div>
        </div>
      ),
    },
    // 11
    {
      body: (
        <div className="text-center text-xl px-10">
          <h3>
            <strong>Be the face of Muskurahat.</strong>
            <br />
            Represent our cause with enthusiasm, sincerity, and professionalism.
          </h3>

          <h3>
            If you’re not ready to give it your best shot, no hard feelings—but
            this might not be for you.
          </h3>

          <button
            onClick={handleNext}
            className="bg-primary text-white rounded-[13px] px-5 py-2 mt-5 font-bold text-[18px] z-10 relative"
          >
            I Am Ready
          </button>
          <div className="absolute bottom-0 left-0 px-10 w-full justify-center flex">
            <img alt="Human2" src={Human2} height={250} width={350} />
          </div>
        </div>
      ),
    },
    // 12
    {
      body: (
        <div className="text-center px-10 text-xl">
          <h3 className="mt-2">
            During this internship you will be raising funds to enable us to
            educate thousands of children-in-need. *
          </h3>
          <div className="flex flex-col w-3/4 m-auto">
            <label
              className="flex items-center justify-start mt-5 bg-[#E31D130D] has-[:checked]:bg-primary border border-primary rounded-[7px] px-5 py-4 text-primary has-[:checked]:text-white z-10 relative"
              htmlFor="accept"
            >
              <span className="bg-[#E31D131A] border border-[#E31D1333] has-[:checked]:border-white p-3 mr-4 rounded-full h-10 w-10 flex justify-center items-center">
                a
                <input
                  type="radio"
                  onClick={handleNext}
                  id="accept"
                  name="funds-raised"
                  hidden
                />
              </span>{" "}
              I accept
            </label>

            <label
              className="flex items-center justify-start mt-5 bg-[#E31D130D] has-[:checked]:bg-primary border border-primary rounded-[7px] px-5 py-4 text-primary has-[:checked]:text-white z-10 relative"
              htmlFor="reject"
            >
              <span className="bg-[#E31D131A] border border-[#E31D1333] has-[:checked]:border-white p-3 mr-4 rounded-full h-10 w-10 flex justify-center items-center">
                b
                <input
                  type="radio"
                  onClick={() => setShowError(true)}
                  id="reject"
                  name="funds-raised"
                  hidden
                />
              </span>
              I don&apos;t accept
            </label>
            {showError && (
              <p className="mt-5 text-primary animate-shake">
                Error: You can only proceed by Accepting The Requirements Of The
                Internship
              </p>
            )}
          </div>
          <div className="absolute bottom-0 left-0 px-10 w-full justify-center flex">
            <img alt="Human2" src={Human2} height={250} width={350} />
          </div>
        </div>
      ),
    },
    // 13
    {
      body: (
        <div className="text-center text-xl px-10">
          <h3 className="mt-5">
            You will be required to reach out to your personal network, tell
            them about the cause and raise funds. *
          </h3>
          <div className="flex flex-col w-3/4 m-auto">
            <label
              className="flex items-center justify-start mt-5 bg-[#E31D130D] has-[:checked]:bg-primary border border-primary rounded-[7px] px-5 py-4 text-primary has-[:checked]:text-white z-10 relative"
              htmlFor="personal-network-accept"
            >
              <span className="bg-[#E31D131A] border border-[#E31D1333] has-[:checked]:border-white p-3 mr-4 rounded-full h-10 w-10 flex justify-center items-center">
                a
                <input
                  type="radio"
                  onClick={handleNext}
                  id="personal-network-accept"
                  name="personal-network"
                  hidden
                />
              </span>{" "}
              I accept
            </label>

            <label
              className="flex items-center justify-start mt-5 bg-[#E31D130D] has-[:checked]:bg-primary border border-primary rounded-[7px] px-5 py-4 text-primary has-[:checked]:text-white z-10 relative"
              htmlFor="personal-network-reject"
            >
              <span className="bg-[#E31D131A] border border-[#E31D1333] has-[:checked]:border-white p-3 mr-4 rounded-full h-10 w-10 flex justify-center items-center">
                b
                <input
                  type="radio"
                  onClick={() => setShowError(true)}
                  id="personal-network-reject"
                  name="personal-network"
                  hidden
                />
              </span>
              I don&apos;t accept
            </label>
            {showError && (
              <p className="mt-5 text-primary animate-shake">
                Error: You can only proceed by Accepting The Requirements Of The
                Internship
              </p>
            )}
          </div>
          <div className="absolute bottom-0 left-0 px-10 w-full justify-center flex">
            <img alt="Human2" src={Human2} height={250} width={350} />
          </div>
        </div>
      ),
    },
    // 14
    {
      body: (
        <div className="text-center px-10 text-xl">
          <h3 className="mt-5">
            You will be required to maintain 2-way communication on Whatsapp,
            send weekly updates, throughout your internship. *
          </h3>
          <div className="flex flex-col w-3/4 m-auto">
            <label
              className="flex items-center justify-start mt-5 bg-[#E31D130D] has-[:checked]:bg-primary border border-primary rounded-[7px] px-5 py-4 text-primary has-[:checked]:text-white z-10 relative"
              htmlFor="whatsapp-accept"
            >
              <span className="bg-[#E31D131A] border border-[#E31D1333] has-[:checked]:border-white p-3 mr-4 rounded-full h-10 w-10 flex justify-center items-center">
                a
                <input
                  type="radio"
                  onClick={handleNext}
                  id="whatsapp-accept"
                  name="whatsapp"
                  hidden
                />
              </span>{" "}
              I accept
            </label>

            <label
              className="flex items-center justify-start mt-5 bg-[#E31D130D] has-[:checked]:bg-primary border border-primary rounded-[7px] px-5 py-4 text-primary has-[:checked]:text-white z-10 relative"
              htmlFor="whatsapp-reject"
            >
              <span className="bg-[#E31D131A] border border-[#E31D1333] has-[:checked]:border-white p-3 mr-4 rounded-full h-10 w-10 flex justify-center items-center">
                b
                <input
                  type="radio"
                  onClick={() => setShowError(true)}
                  id="whatsapp-reject"
                  name="whatsapp"
                  hidden
                />
              </span>
              I don&apos;t accept
            </label>
            {showError && (
              <p className="mt-5 text-primary animate-shake">
                Error: You can only proceed by Accepting The Requirements Of The
                Internship
              </p>
            )}
          </div>
          <div className="absolute bottom-0 left-0 px-10 w-full justify-center flex">
            <img alt="Human2" src={Human2} height={250} width={350} />
          </div>
        </div>
      ),
    },
    // 15
    {
      body: (
        <div className="text-center px-10 text-xl">
          <h3 className="mt-5">
            Would you like to sign up for this internship? *
          </h3>
          <div className="flex flex-col w-3/4 m-auto">
            <label
              className="flex items-center justify-start mt-5 bg-[#E31D130D] has-[:checked]:bg-primary border border-primary rounded-[7px] px-5 py-4 text-primary has-[:checked]:text-white z-10 relative"
              htmlFor="yes"
            >
              <span className="bg-[#E31D131A] border border-[#E31D1333] has-[:checked]:border-white p-3 mr-4 rounded-full h-10 w-10 flex justify-center items-center">
                a
                <input
                  type="radio"
                  onClick={handleNext}
                  id="yes"
                  name="signup"
                  hidden
                />
              </span>{" "}
              Yes
            </label>

            <label
              className="flex items-center justify-start mt-5 bg-[#E31D130D] has-[:checked]:bg-primary border border-primary rounded-[7px] px-5 py-4 text-primary has-[:checked]:text-white z-10 relative"
              htmlFor="no"
            >
              <span className="bg-[#E31D131A] border border-[#E31D1333] has-[:checked]:border-white p-3 mr-4 rounded-full h-10 w-10 flex justify-center items-center">
                b
                <input
                  type="radio"
                  onClick={() => setShowError(true)}
                  id="no"
                  name="signup"
                  hidden
                />
              </span>
              No
            </label>

            {showError && (
              <p className="mt-5 text-primary animate-shake">
                Error: You can only proceed by Accepting The Requirements Of The
                Internship
              </p>
            )}
          </div>
          <div className="absolute bottom-0 left-0 px-10 w-full justify-center flex">
            <img alt="Human2" src={Human2} height={250} width={350} />
          </div>
        </div>
      ),
    },
    // 16
    {
      body: (
        <div className="text-center px-10 text-xl">
          <h3 className="mt-5">
            <span className="font-bold">Which city are you from? *</span>
          </h3>
          <input
            onChange={(e) => setFormData({ ...formData, city: e.target.value })}
            value={formData.city}
            type="text"
            placeholder="(Type your answer here)"
            className="w-full bg-transparent border-b-2 border-black text-center text-[14px] mt-10"
          />
          <button
            disabled={!isStepComplete}
            onClick={handleNext}
            className="bg-primary disabled:bg-grey disabled:cursor-not-allowed disabled:opacity-50 text-white rounded-[13px] px-5 py-2 mt-5 font-bold text-[18px] z-10 relative"
          >
            OK
          </button>
          <div className="absolute bottom-0 left-0 px-10 w-full justify-center flex">
            <img alt="Human2" src={Human2} height={250} width={350} />
          </div>
        </div>
      ),
    },
    // 17
    {
      body: (
        <div className="text-center px-10 text-xl">
          <h3 className="mt-5">
            <span className="font-bold">
              Your educational qualifications? *
            </span>
          </h3>
          <input
            onChange={(e) =>
              setFormData({
                ...formData,
                educationalQualifications: e.target.value,
              })
            }
            value={formData.educationalQualifications}
            type="text"
            placeholder="(Type your answer here)"
            className="w-full bg-transparent border-b-2 border-black text-center text-[14px] mt-10"
          />
          <button
            disabled={!isStepComplete}
            onClick={handleNext}
            className="bg-primary disabled:bg-grey disabled:cursor-not-allowed disabled:opacity-50 text-white rounded-[13px] px-5 py-2 mt-5 font-bold text-[18px] z-10 relative"
          >
            OK
          </button>
          <div className="absolute bottom-0 left-0 px-10 w-full justify-center flex">
            <img alt="Human2" src={Human2} height={250} width={350} />
          </div>
        </div>
      ),
    },
    // 18
    {
      body: (
        <div className="text-center px-10 text-xl">
          <h3 className="mt-5]">
            Why would you like to help the{" "}
            <span className="font-bold">thousands of children</span> that are in
            need of quality education? *
          </h3>
          <h4 className="text-[15px] mt-5">
            (we would like to hear from you and{" "}
            <span className="font-bold">NOT CHATGPT</span>, please answer by
            yourself and with minimum 10 words)
          </h4>
          <input
            onChange={(e) =>
              setFormData({ ...formData, reason: e.target.value })
            }
            value={formData.reason}
            type="text"
            placeholder="(Type your answer here)"
            className="w-full bg-transparent border-b-2 border-black text-center text-[14px] mt-10"
          />
          <button
            disabled={!isStepComplete || isLoading}
            onClick={async () => {
              await handleSubmit();
              handleNext();
            }}
            className="bg-primary disabled:bg-grey disabled:cursor-not-allowed  text-white rounded-[13px] px-5 py-2 mt-5 font-bold text-[18px] z-10 relative"
          >
            {isLoading ? (
              // loading icon
              <svg
                className="animate-spin h-8 w-8"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V4a10 10 0 00-1.93 2.4"
                ></path>
              </svg>
            ) : (
              "Submit"
            )}
          </button>
          <div className="absolute bottom-0 left-0 px-10 w-full justify-center flex">
            <img alt="Human" src={HumanGroup} height={250} width={350} />
          </div>
        </div>
      ),
    },
    // 19
    {
      body: (
        <div className="text-center px-10">
          <button
            onClick={() => navigate("/user/dashboard")}
            className="bg-primary text-white rounded-[13px] px-5 py-2 mt-5 font-bold text-[18px] z-10 relative"
          >
            Letss GO!!
          </button>

          <div className="absolute bottom-0 left-0 px-10 w-full justify-center flex">
            <img alt="Lets" src={Lets} height={250} width={350} />
          </div>
        </div>
      ),
    },
    // {
    //   body: (
    //     <div className="text-center px-10">
    //       <h2 className="font-bold text-[18px]">
    //         Your one step today is going to change the future of many
    //       </h2>
    //       <h3 className="text-[14px] mt-5">
    //         We are going to reach out to you within 48 hours. Meanwhile do share
    //         the word about this internship, and follow us on{" "}
    //         <Link
    //           href="https://www.instagram.com/muskurahat_foundation"
    //           target="_blank"
    //           className="text-primary font-bold"
    //         >
    //           Instagram
    //         </Link>{" "}
    //         to learn more about how are we serving our children.{" "}
    //       </h3>

    //       <button
    //         onClick={() =>
    //           navigate(
    //             "https://api.whatsapp.com/send?text=Hey%2C%20check%20out%20this%20Internship%20opportunity%20https%3A%2F%2Fmuskurahat.org.in%2Finternship-application%3Futm_source%3Dref",
    //           )
    //         }
    //         className="bg-primary text-white rounded-[13px] px-5 py-2 mt-5 font-bold text-[18px] z-10 relative"
    //       >
    //         REFER THIS INTERNSHIP TO A FRIEND
    //       </button>
    //       <div className="absolute bottom-0 left-0 px-10 w-full justify-center flex">
    //         <img alt="Human" src={Human2} height={250} width={350} />
    //       </div>
    //     </div>
    //   ),
    // },
  ];

  return (
    <div className="h-[100dvh]">
      <div
        className={`h-dvh h-full bg-[url('assets/images/pages/onboarding/background.webp')] bg-cover overflow-x-hidden relative`}
      >
        <div
          style={{
            width: steps.length * 100 + "%",
            // width: "100%",
            transform: `translateX(-${currentStep * (100 / steps.length)}%)`,
          }}
          className={`h-full flex justify-center items-center h-dvh transition-transform duration-500 ease-in-out`}
        >
          {steps.map((step, index) => (
            <div
              key={index}
              className="w-screen h-full flex justify-center items-center relative"
            >
              {step.body}
            </div>
          ))}
        </div>
        <div className="absolute bottom-5 right-5 flex gap-1 z-10">
          <button
            className="bg-primary disabled:bg-grey disabled:cursor-not-allowed disabled:opacity-50"
            onClick={handlePrev}
            disabled={currentStep === 0 || currentStep === 19}
          >
            <svg
              stroke="currentColor"
              strokeWidth="0"
              viewBox="0 0 20 20"
              height="1.4em"
              width="1.4em"
              fill="white"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>

          <button
            className="bg-primary disabled:bg-grey disabled:cursor-not-allowed disabled:opacity-50"
            onClick={handleNext}
            disabled={!isStepComplete || steps.length - 1 === currentStep}
          >
            {/* up arrow svg */}
            <svg
              stroke="currentColor"
              strokeWidth="0"
              viewBox="0 0 20 20"
              height="1.4em"
              width="1.4em"
              fill="white"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default InternshipOnboarding;
