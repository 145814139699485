export const questions = [
  {
    question: "You regularly make new friends.",
    parameter: "Mind (Extraversion vs. Introversion)",
  },
  {
    question:
      "Complex and novel ideas excite you more than simple and straightforward ones.",
    parameter: "Energy (Intuition vs. Observing)",
  },
  {
    question:
      "You usually feel more persuaded by what resonates emotionally with you than by factual arguments.",
    parameter: "Nature (Thinking vs. Feeling)",
  },
  {
    question: "Your living and working spaces are clean and organized.",
    parameter: "Tactics (Judging vs. Prospecting)",
  },
  {
    question: "You usually stay calm, even under a lot of pressure.",
    parameter: "Identity (Assertive vs. Turbulent)",
  },
  {
    question:
      "You find the idea of networking or promoting yourself to strangers very daunting.",
    parameter: "Mind (Extraversion vs. Introversion)",
  },
  {
    question:
      "You prioritize and plan tasks effectively, often completing them well before the deadline.",
    parameter: "Tactics (Judging vs. Prospecting)",
  },
  {
    question:
      "People’s stories and emotions speak louder to you than numbers or data.",
    parameter: "Nature (Thinking vs. Feeling)",
  },
  {
    question: "You like to use organizing tools like schedules and lists.",
    parameter: "Tactics (Judging vs. Prospecting)",
  },
  {
    question:
      "Even a small mistake can cause you to doubt your overall abilities and knowledge.",
    parameter: "Identity (Assertive vs. Turbulent)",
  },
  {
    question:
      "You feel comfortable just walking up to someone you find interesting and striking up a conversation.",
    parameter: "Mind (Extraversion vs. Introversion)",
  },
  {
    question:
      "You are not too interested in discussions about various interpretations of creative works.",
    parameter: "Energy (Intuition vs. Observing)",
  },
  {
    question:
      "You prioritize facts over people’s feelings when determining a course of action.",
    parameter: "Nature (Thinking vs. Feeling)",
  },
  {
    question: "You often allow the day to unfold without any schedule at all.",
    parameter: "Tactics (Judging vs. Prospecting)",
  },
  {
    question:
      "You rarely worry about whether you make a good impression on people you meet.",
    parameter: "Identity (Assertive vs. Turbulent)",
  },
  {
    question: "You enjoy participating in team-based activities.",
    parameter: "Mind (Extraversion vs. Introversion)",
  },
  {
    question: "You enjoy experimenting with new and untested approaches.",
    parameter: "Energy (Intuition vs. Observing)",
  },
  {
    question: "You prioritize being sensitive over being completely honest.",
    parameter: "Nature (Thinking vs. Feeling)",
  },
  {
    question:
      "You actively seek out new experiences and knowledge areas to explore.",
    parameter: "Energy (Intuition vs. Observing)",
  },
  {
    question:
      "You are prone to worrying that things will take a turn for the worse.",
    parameter: "Identity (Assertive vs. Turbulent)",
  },
  {
    question: "You enjoy solitary hobbies or activities more than group ones.",
    parameter: "Mind (Extraversion vs. Introversion)",
  },
  {
    question:
      "You cannot imagine yourself writing fictional stories for a living.",
    parameter: "Energy (Intuition vs. Observing)",
  },
  {
    question:
      "You favor efficiency in decisions, even if it means disregarding some emotional aspects.",
    parameter: "Nature (Thinking vs. Feeling)",
  },
  {
    question: "You prefer to do your chores before allowing yourself to relax.",
    parameter: "Tactics (Judging vs. Prospecting)",
  },
  {
    question:
      "In disagreements, you prioritize proving your point over preserving the feelings of others.",
    parameter: "Nature (Thinking vs. Feeling)",
  },
  {
    question:
      "You usually wait for others to introduce themselves first at social gatherings.",
    parameter: "Mind (Extraversion vs. Introversion)",
  },
  {
    question: "Your mood can change very quickly.",
    parameter: "Identity (Assertive vs. Turbulent)",
  },
  {
    question: "You are not easily swayed by emotional arguments.",
    parameter: "Nature (Thinking vs. Feeling)",
  },
  {
    question: "You often end up doing things at the last possible moment.",
    parameter: "Tactics (Judging vs. Prospecting)",
  },
  {
    question: "You enjoy debating ethical dilemmas.",
    parameter: "Nature (Thinking vs. Feeling)",
  },
  {
    question: "You usually prefer to be around others rather than on your own.",
    parameter: "Mind (Extraversion vs. Introversion)",
  },
  {
    question:
      "You become bored or lose interest when the discussion gets highly theoretical.",
    parameter: "Energy (Intuition vs. Observing)",
  },
  {
    question:
      "When facts and feelings conflict, you usually find yourself following your heart.",
    parameter: "Nature (Thinking vs. Feeling)",
  },
  {
    question:
      "You find it challenging to maintain a consistent work or study schedule.",
    parameter: "Tactics (Judging vs. Prospecting)",
  },
  {
    question: "You rarely second-guess the choices that you have made.",
    parameter: "Identity (Assertive vs. Turbulent)",
  },
  {
    question: "Your friends would describe you as lively and outgoing.",
    parameter: "Mind (Extraversion vs. Introversion)",
  },
  {
    question:
      "You are drawn to various forms of creative expression, such as writing.",
    parameter: "Energy (Intuition vs. Observing)",
  },
  {
    question:
      "You usually base your choices on objective facts rather than emotional impressions.",
    parameter: "Nature (Thinking vs. Feeling)",
  },
  {
    question: "You like to have a to-do list for each day.",
    parameter: "Tactics (Judging vs. Prospecting)",
  },
  {
    question: "You rarely feel insecure.",
    parameter: "Identity (Assertive vs. Turbulent)",
  },
  {
    question: "You avoid making phone calls.",
    parameter: "Mind (Extraversion vs. Introversion)",
  },
  {
    question: "You enjoy exploring unfamiliar ideas and viewpoints.",
    parameter: "Energy (Intuition vs. Observing)",
  },
  {
    question: "You can easily connect with people you have just met.",
    parameter: "Mind (Extraversion vs. Introversion)",
  },
  {
    question:
      "If your plans are interrupted, your top priority is to get back on track as soon as possible.",
    parameter: "Tactics (Judging vs. Prospecting)",
  },
  {
    question:
      "You are still bothered by mistakes that you made a long time ago.",
    parameter: "Identity (Assertive vs. Turbulent)",
  },
  {
    question:
      "You are not too interested in discussing theories on what the world could look like in the future.",
    parameter: "Energy (Intuition vs. Observing)",
  },
  {
    question: "Your emotions control you more than you control them.",
    parameter: "Identity (Assertive vs. Turbulent)",
  },
  {
    question:
      "When making decisions, you focus more on how the affected people might feel than on what is most logical or efficient.",
    parameter: "Nature (Thinking vs. Feeling)",
  },
  {
    question:
      "Your personal work style is closer to spontaneous bursts of energy than organized and consistent efforts.",
    parameter: "Tactics (Judging vs. Prospecting)",
  },
  {
    question:
      "When someone thinks highly of you, you wonder how long it will take them to feel disappointed in you.",
    parameter: "Identity (Assertive vs. Turbulent)",
  },
  {
    question:
      "You would love a job that requires you to work alone most of the time.",
    parameter: "Mind (Extraversion vs. Introversion)",
  },
  {
    question:
      "You believe that pondering abstract philosophical questions is a waste of time.",
    parameter: "Energy (Intuition vs. Observing)",
  },
  {
    question:
      "You feel more drawn to busy, bustling atmospheres than to quiet, intimate places.",
    parameter: "Mind (Extraversion vs. Introversion)",
  },
  {
    question:
      "If a decision feels right to you, you often act on it without needing further proof.",
    parameter: "Nature (Thinking vs. Feeling)",
  },
  {
    question: "You often feel overwhelmed.",
    parameter: "Identity (Assertive vs. Turbulent)",
  },
  {
    question:
      "You complete things methodically without skipping over any steps.",
    parameter: "Tactics (Judging vs. Prospecting)",
  },
  {
    question:
      "You prefer tasks that require you to come up with creative solutions rather than follow concrete steps.",
    parameter: "Energy (Intuition vs. Observing)",
  },
  {
    question:
      "You are more likely to rely on emotional intuition than logical reasoning when making a choice.",
    parameter: "Nature (Thinking vs. Feeling)",
  },
  {
    question: "You struggle with deadlines.",
    parameter: "Tactics (Judging vs. Prospecting)",
  },
  {
    question: "You feel confident that things will work out for you.",
    parameter: "Identity (Assertive vs. Turbulent)",
  },
];
